<template>
  <b-overlay :show="loadingArticles" spinner-variant="primary">
    <k-data-table
      id="MessagesTable"
      :options="dtOptions"
      :dataList="articles"
      class="table-hover simple-table dataTable"
      ref="datatable"
    >
    </k-data-table>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import $ from 'jquery';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { hirService } from '../../services/HirService';
import {
  addTooltip,
  formatHtmlBeforeDtExport,
  formatNumber,
} from '../../utils/common';

export default {
  name: 'articles-table',
  model: {
    prop: 'selected',
    event: 'change',
  },
  data() {
    return {
      loadingArticles: false,
      articles: [],
    };
  },
  mounted() {},
  created() {
    console.log('created');
    this.GetArticles();
  },
  methods: {
    SetSelectedItem(ids) {
      this.$emit('change', ids);
    },
    async GetArticles() {
      this.loadingArticles = true;
      try {
        let articles = await hirService.GetHirek({
          superAdminFl: true,
          cikkekhez: true,
        });
        this.articles = Object.freeze(articles);
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.loadingArticles = false;
    },
    DrawSelectsOnDT(ids) {
      var selected = ids
        .map(m => {
          return `[data-id="${m.id}"]`;
        })
        .join(',');
      if (selected) {
        $(this.$refs.datatable.$el)
          .DataTable()
          .rows(selected, { selected: false })
          .select();
      }
      let notSelected = $(this.$refs.datatable.$el)
        .find('tr[data-id]')
        .not(selected);

      if (notSelected.length > 0) {
        $(this.$refs.datatable.$el)
          .DataTable()
          .rows(notSelected, { selected: true })
          .deselect();
      }
    },
    async DeleteArticle(id) {
      let article = this.articles.find(f => f.Id == id);
      let success = await NotificationFunctions.ConfirmModal(
        'Cikk törlése',
        `Biztos, hogy törli a "${article.Cim}" című cikket?`
      );
      if (success) {
        try {
          await hirService.HirTorlese({ hirId: id });
          this.GetArticles();
        } catch (error) {
          NotificationFunctions.AjaxError({ error });
        }
      }
    },
  },
  computed: {
    ...mapGetters({}),
    dtOptions() {
      let vm = this;
      let tableData = this.tableData;
      let toDateTime = this.$options.filters.toDateTime;
      let dtOptions = {
        initComplete: function(settings, json) {
          var table = '#' + settings.sTableId + '_wrapper';

          $(table + ' .clear-searchbar').click(function() {
            $('.dataTables_filter .form-control').val('');
            $(vm.$refs.datatable.$el)
              .DataTable()
              .search('')
              .draw();
          });
          // $(table + ' .dt-search .top-buttons').append(
          //   `<button id="UjFelvetel"  class="btn btn-primary btn-sm">
          //     <i class="fas fa-plus text-white"></i>
          //   </button>`
          // );
          // let $ujfelvetelBtn = $(table + ' #UjFelvetel');
          // addTooltip($ujfelvetelBtn, 'Új cikk');
          // $ujfelvetelBtn.click(function() {
          //   vm.$router.push({ name: 'Article' });
          // });
        },
        mark: {
          className: 'mark-red',
        },
        order: [[5, 'desc']],
        orderCellsTop: true,
        bSortClasses: false,
        aoColumns: [
          {
            sTitle: 'Id',
            sClass: '',
            mRender: function(data, type, row, meta) {
              if (type == 'export') {
                return row.Id;
              }
              return formatNumber(row.Id);
            },
          },
          {
            sTitle: 'Cím',
            sClass: '',
            mRender: function(data, type, row, meta) {
              return row.Cim;
            },
          },
          {
            sTitle: 'Kategória',
            sClass: '',
            mRender: function(data, type, row, meta) {
              let resultArr = [];
              if (row.VezerHirFl == true) {
                resultArr.push(
                  '<span class="badge badge-outline badge-success font-weight-normal font-size-xs mb-1 mr-1">Vezércikk</span>'
                );
              }
              if (row.KiemeltKategoria != null) {
                resultArr.push(
                  '<span class="badge badge-outline badge-primary font-weight-normal font-size-xs mb-1 mr-1">' +
                    row.KiemeltKategoria.Nev +
                    '</span>'
                );
              }

              if (row.Kategoriak != null) {
                row.Kategoriak.forEach(kategoria => {
                  resultArr.push(
                    '<span class="badge badge-outline badge-primary font-weight-normal font-size-xs mb-1 mr-1">' +
                      kategoria.Nev +
                      '</span>'
                  );
                });
              }
              let result = resultArr.join('<br/>');
              if (type == 'export') {
                return formatHtmlBeforeDtExport(result);
              }
              return result;
            },
          },
          {
            sTitle: 'Szerző',
            sClass: 'width-fit-content',
            mRender: function(data, type, row, meta) {
              return (
                (row.LetrehozoFelhasznalo?.Nev ?? 'Ismeretlen') +
                  '<br/>' +
                  row.Szerzo?.Nev ?? 'Nincs'
              );
            },
          },
          {
            sTitle: 'Megjelenés dátuma',
            sClass: '',
            mRender: function(data, type, row, meta) {
              return toDateTime(row.MegjelenesDatuma) || 'Nincs megadva';
            },
          },
          {
            sTitle: 'Módosító',
            sClass: '',
            mRender: function(data, type, row, meta) {
              return row.ModositasDatuma
                ? toDateTime(row.ModositasDatuma) +
                    '<br/>' +
                    row.UtolsoModositoFelhasznalo?.Nev
                : 'Nincs megadva';
            },
          },
          {
            sTitle: 'Olvasottság',
            sClass: 'text-right',
            mRender: function(data, type, row, meta) {
              if (type == 'export') {
                return row.HanyszorOlvastak;
              }
              return formatNumber(row.HanyszorOlvastak);
            },
          },
          {
            sTitle: 'Státusza',
            sClass: '',
            mRender: function(data, type, row, meta) {
              return row.Statusz?.Nev ?? 'Nincs';
            },
          },
          {
            sTitle: '',
            sClass: 'd-print-none dt-td-noClick all',
            bSortable: false,
            mRender: function(data, type, row, meta) {
              return '<i class="fa fa-trash pointer delete-article" data-toggle="k-tooltip" title="Cikk törlése"></i>';
            },
          },
        ],
        pageLength: 25,
        responsive: false,
        deferRender: true,
        select: false,
        sDom: `<'row dt-panelmenu align-items-center'<'col-4 'i><'col-8 dt-search'<'top-buttons d-flex flex-row-reverse align-items-center'f>>>
          <'row'<'col-sm-12'tr>>
          <'row dt-panelfooter mt-1'<'col-3' B><'col-9 dt-lp d-flex justify-content-end'l p>>`,
        //buttons: ['copy', 'excel', 'pdf', 'print'],
        drawCallback: function(settings) {
          var table = '#' + settings.sTableId + '_wrapper';
          var lastRowColSpan = settings.aoColumns.length;
          $('[data-toggle="k-tooltip"]').each(function() {
            addTooltip(this);
          });
        },
        createdRow: function(row, data, rowIndex) {
          $(row).attr('data-id', data.Id);

          var selector = 'td:not(.dt-td-noClick)';
          $(row)
            .find(selector)
            .addClass('pointer');
          $(row)
            .find(selector)
            .on('click', this, function(e) {
              var id = $(this)
                .closest('tr')
                .attr('data-id');
              vm.$router.push({ name: 'Article', params: { id: id } });
            });

          $(row)
            .find('.delete-article')
            .click(function(e) {
              var id = $(this)
                .closest('tr')
                .attr('data-id');
              vm.DeleteArticle(id);
            });
        },
        buttons: [
          {
            extend: 'print',
            text: '<i class="fas fa-print text-grey"></i>',
            className: 'btn btn-primary',
          },
          {
            extend: 'excelHtml5',
            text: '<i class="fas fa-download mr-0 text-grey"></i>',
            className: 'btn btn-primary',
            exportOptions: { orthogonal: 'export' },
          },
        ],
      };

      return dtOptions;
    },
  },
  watch: {
    selected: {
      handler: async function(curr) {
        await this.$nextTick();
        this.DrawSelectsOnDT(curr);
      },
      deep: true,
    },
  },
  props: {
    selected: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
};
</script>
